import React from "react";

import {
  Broadcast,
  ChartLine,
  CurrencyCircleDollar,
  DesktopTower,
  UploadSimple,
  VideoCamera,
  DeviceMobile,
  Cloud,
  Microphone,
  ComputerTower,
  SimCard
} from "phosphor-react";
import { Container, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react";

import FeatureCell from "./FeatureCell";

const features = [
  {
    icon: <Cloud />,
    heading: "Thingwire Cloud Platform",
    text: "Open, device-agnostic architecture supporting various protocols for seamless integration and scalability.",
  },
  {
    icon: <SimCard />,
    heading: "Thingwire IoT module",
    text: "Thingwire network and SoC's for no code IoT integration for you hardware",
  },
  {
    icon: <DeviceMobile />,
    heading: "Mobile Applications",
    text: "White-labelled apps for Android and iOS with intuitive interfaces and multi-language support.",
  },
  {
    icon: <Microphone />,
    heading: "Voice Assistant Integrations",
    text: "Compatibility with Google Home and Amazon Alexa for convenient voice control.",
  },
  {
    icon: <ComputerTower />,
    heading: "Thingwire Rtos",
    text: "Thingwire rtos for easy integration with thingwire cloud",
  },
  {
    icon: <UploadSimple />,
    heading: "Third party integrations",
    text: "Easy integratioin with other third party IoT platforms",
  },

];


function FeatureGrid() {
  return (
    <Container
      maxW={{ base: "container.sm", xl: "container.xl" }}
      py={{ base: "3rem", md: "6.5rem" }}
    >
      <VStack spacing={{ base: "3rem", md: "7.5rem" }}>
        {/* Heading */}
        <VStack
          spacing="1rem"
          alignItems={{ base: "start", md: "center" }}
          textAlign={{ base: "start", md: "center" }}
        >
          <Heading as="h1" size="h1">
            Our Product Ecosystem
          </Heading>
          <Text lineHeight="1.5">
            Thingwire's IoT & AI Product Ecosystem is designed to provide a comprehensive, flexible, and secure solution for a wide range of appliances and consumer electronics.
          </Text>
        </VStack>
        {/* Grid */}
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 3 }}
          spacing={{ base: "3rem", md: "5rem", xl: "7.5rem" }}
          maxW="57rem"
        >
          {features.map((feature, index) => (
            <FeatureCell key={`feature-${index}`} {...feature} />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
}

export default FeatureGrid;
