// import React from "react";

// import { Box, Container, Image, Wrap, WrapItem } from "@chakra-ui/react";

// import partnerLogos from "assets/json/partner-logos.json";

// function LogoCloud() {
//   return (
//     <Box bg="gray.900">
//       <Container
//         maxW={{ base: "container.sm", xl: "container.xl" }}
//         py={{ base: "3.125rem", md: "4.25rem" }}
//       >
//         <Wrap
//           spacing={{ base: "3rem", md: "3rem" }}
//           justify={{ base: "center", xl: "space-between" }}
//         >
//           {/* {partnerLogos.map((logo, index) => (
//             <WrapItem key={index}>
//               <Image
//                 src={logo}
//                 w="100%"
//                 h={["16px", "20px", "24px", "28px", "32px"]}
//               />
//             </WrapItem>
//           ))} */}
//         </Wrap>
//       </Container>
//     </Box>
//   );
// }

// export default LogoCloud;
import React from "react";
import { Box, Container, Text, Wrap, WrapItem, Heading } from "@chakra-ui/react";

const protocols = [
  "WiFi",
  "BLE",
  "Matter",
  "Zigbee",
  "Thread",
  "DALI",
  "Ethernet",
  "Mqtt"
];

function LogoCloud() {
  return (
    <Box bg="gray.900">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "3.125rem", md: "4.25rem" }}
      >
        <Heading as="h2" size="lg" color="white" textAlign="center" mb={{ base: "2rem", md: "3rem" }}>
          Supported Protocols
        </Heading>
        <Wrap
          spacing={{ base: "2rem", md: "2rem" }}
          justify={{ base: "center", xl: "space-between" }}
        >
          {protocols.map((protocol, index) => (
            <WrapItem key={index}>
              <Text
                color="white"
                fontSize={["12px", "14px", "16px", "18px", "20px"]}
                fontWeight="bold"
              >
                {protocol}
              </Text>
            </WrapItem>
          ))}
        </Wrap>
      </Container>
    </Box>
  );
}

export default LogoCloud;
