import React from "react";
import { EnvelopeSimple } from "phosphor-react";
import { Box, Circle, Container, Heading, VStack } from "@chakra-ui/react";

import DonateOverlay from "./DonateOverlay";
import Feature from "./Feature";
import HoverBadge from "components/HoverBadge";
import Parallax from "components/Parallax";

function FeatureImages() {
  return (
    <Box bg="gray.900">
      <Container
        maxW={{ base: "container.sm", xl: "container.xl" }}
        py={{ base: "3rem", md: "6.5rem" }}
      >
        <Heading
          as="h1"
          size="h1"
          color="white"
          pb={{ base: "3rem", md: "6.5rem" }}
          textAlign={{ base: "start", md: "center" }}
        >
          How Thingwire Can Help You Grow
        </Heading>
        <VStack spacing={{ base: "3rem", md: "6.5rem" }}>
          <Feature
            heading="Transformative Innovation"
            text="With Thingwire, you can easily integrate advanced IoT functionalities into your products, transforming them into smart devices. This not only adds value to your offerings but also positions your brand as an innovator in the market."
            imageSrc="assets/images/features/f1.png"
            imageAlt="Feature Image 1"
            to="/"
          >
            <Parallax position="absolute" top="-1rem" left="-1rem">
              <DonateOverlay />
            </Parallax>
          </Feature>
          <Feature
            heading="Enhanced User Experience"
            text="Our platform provides seamless connectivity and control, enabling end-users to enjoy a superior, hassle-free experience. Features like mobile app control and voice assistant compatibility enhance convenience, making your products more attractive to tech-savvy consumers."
            imageSrc="assets/images/features/f2.png"
            imageAlt="Feature Image 2"
            reverse={true}
            to="/"
          >
            {/* <HoverBadge
              borderRadius="0.75rem"
              p="1rem"
              fontSize="32px"
              position="absolute"
              top="-1rem"
              right="-1rem"
            > */}
            {/* <EnvelopeSimple /> */}
            {/* <Circle
                size="0.75rem"
                bg="blue.400"
                position="absolute"
                top="1rem"
                right="0.85rem"
                border="2px"
                borderColor="white"
              />
            </HoverBadge> */}
          </Feature>
          <Feature
            heading="Faster Time-to-Market"
            text="Thingwire’s ready-to-use modules and flexible platform reduce development time, allowing you to launch smart products faster. This quick turnaround helps you stay ahead of competitors and capture market opportunities more effectively."
            imageSrc="assets/images/features/f3.png"
            imageAlt="Feature Image 1"
            to="/"
          />
          <Feature
            heading="Cost Efficiency"
            text="By leveraging Thingwire’s scalable platform and device-agnostic architecture, you can minimize integration costs and reduce the need for extensive in-house development. This leads to significant cost savings and a better return on investment."
            imageSrc="assets/images/features/f4.png"
            imageAlt="Feature Image 2"
            reverse={true}
            to="/"
          />
          <Feature
            heading="Scalable and Flexible Solutions"
            text="Our platform is designed to grow with your business. Whether you’re starting with a few smart products or planning to expand your entire lineup, Thingwire provides the scalability and flexibility needed to support your growth."
            imageSrc="assets/images/features/f5.png"
            imageAlt="Feature Image 1"
            to="/"
          />
          <Feature
            heading="Data-Driven Insights"
            text="Thingwire’s cloud suite offers advanced analytics and monitoring capabilities, providing valuable insights into product performance and user behavior. This data helps you make informed decisions, improve product quality, and develop targeted marketing strategies."
            imageSrc="assets/images/features/f8.png"
            imageAlt="Feature Image 2"
            reverse={true}
            to="/"
          />
          <Feature
            heading="Increased Customer Engagement"
            text="With smart features and enhanced connectivity, your products can offer a more interactive experience. This increased engagement leads to higher customer satisfaction and loyalty, fostering long-term relationships and repeat business."
            imageSrc="assets/images/features/f6.png"
            imageAlt="Feature Image 1"
            to="/"
          />
          <Feature
            heading="Enhanced Security"
            text="Security is a top priority at Thingwire. Our platform incorporates military-grade security measures to protect your data and ensure the privacy of your users, building trust and credibility for your brand."
            imageSrc="assets/images/features/f7.png"
            imageAlt="Feature Image 2"
            reverse={true}
            to="/"
          />
        </VStack>
      </Container>
    </Box>
  );
}

export default FeatureImages;
