const Input = {
  sizes: {
    md: {
      field: {
        height: "3.25rem",
        px: "1.125rem",
        borderRadius: "0.875rem",
      },
    },
  },
};

export default Input;
