import React from "react";
import {
  Container,
  Heading,
  Text,
  Box,
  Link,
  List,
  ListItem,
  VStack,
  Divider,
} from "@chakra-ui/react";

function PrivacyPolicy() {
  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="2xl">
          Privacy Policy
        </Heading>
        
        <Text color="gray.600">
          Effective Date: March 25, 2024
        </Text>

        <Box>
          <Text>
            <strong>App Name:</strong> Thingwire<br />
            <strong>Company Name:</strong> Thingcrest Labs Pvt. Ltd.<br />
            <strong>Contact Email:</strong>{" "}
            <Link href="mailto:support@thingwire.io" color="blue.500">
              support@thingwire.io
            </Link>
          </Text>
        </Box>

        <Divider />

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            1. Introduction
          </Heading>
          <Text mb={4}>
            Thingcrest Labs Pvt. Ltd. ("we", "our", or "us") values your privacy. This Privacy Policy explains how we collect, use, and protect your information when you use our mobile application, Thingwire (the "App").
          </Text>
          <Text>
            By using the App, you agree to the practices described in this Privacy Policy.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            2. Information We Collect
          </Heading>
          <Text fontWeight="bold" mb={2}>a. Personal Information</Text>
          <Text mb={4}>
            Name, email address (only when provided by the user for login, support, or feedback).<br />
            Device identifiers such as UUID or MAC address (for provisioning and identifying your IoT devices).
          </Text>

          <Text fontWeight="bold" mb={2}>b. IoT Device Data</Text>
          <Text mb={4}>
            Device states (e.g., ON/OFF, temperature, brightness).<br />
            Scheduled actions and automation rules created by you.<br />
            Device performance, usage statistics, and alerts.
          </Text>

          <Text fontWeight="bold" mb={2}>c. Diagnostic and Usage Data</Text>
          <Text>
            Crash reports, app performance data, and logs to improve stability and features.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            3. How We Use Your Information
          </Heading>
          <List spacing={2} styleType="disc" pl={6}>
            <ListItem>To provide, operate, and maintain the IoT services.</ListItem>
            <ListItem>To enable control, scheduling, and automation of your devices.</ListItem>
            <ListItem>To respond to your support requests and communicate with you.</ListItem>
            <ListItem>To improve app performance and user experience.</ListItem>
            <ListItem>To comply with legal obligations and protect our legal rights.</ListItem>
          </List>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            4. Sharing of Information
          </Heading>
          <Text mb={4}>
            We do not sell or rent your personal information to third parties.
          </Text>
          <Text mb={2}>We may share data:</Text>
          <List spacing={2} styleType="disc" pl={6}>
            <ListItem>With cloud service providers for infrastructure support (e.g., databases, hosting).</ListItem>
            <ListItem>With partners or contractors under strict confidentiality agreements.</ListItem>
            <ListItem>When required by law, legal processes, or regulatory authorities.</ListItem>
          </List>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            5. Data Retention
          </Heading>
          <Text>
            We retain your information as long as you maintain an account with us or as necessary to fulfill the purposes outlined in this policy. You may request data deletion by contacting us at{" "}
            <Link href="mailto:support@thingwire.io" color="blue.500">
              support@thingwire.io
            </Link>.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            6. Data Security
          </Heading>
          <Text>
            We use industry-standard security practices to protect your data, including encryption, secure cloud storage, and restricted access. However, no method of data transmission over the internet is 100% secure.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            7. Your Rights
          </Heading>
          <Text mb={2}>You have the right to:</Text>
          <List spacing={2} styleType="disc" pl={6} mb={4}>
            <ListItem>Access or correct your personal information.</ListItem>
            <ListItem>Request deletion of your account or data.</ListItem>
            <ListItem>Withdraw consent for data processing (if applicable).</ListItem>
          </List>
          <Text>
            To exercise these rights, email us at{" "}
            <Link href="mailto:support@thingwire.io" color="blue.500">
              support@thingwire.io
            </Link>.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            8. Third-Party Services
          </Heading>
          <Text>
            Our App may use third-party services (e.g., Firebase, analytics tools). These services have their own privacy policies, which we encourage you to review.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            9. Children's Privacy
          </Heading>
          <Text>
            The App is not intended for use by children under the age of 13. We do not knowingly collect data from children.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            10. Changes to This Privacy Policy
          </Heading>
          <Text>
            We may update this Privacy Policy from time to time. We will notify users of any changes via in-app notifications or email.
          </Text>
        </Box>

        <Box>
          <Heading as="h2" size="lg" mb={4}>
            11. Contact Us
          </Heading>
          <Text>
            If you have any questions or concerns regarding this Privacy Policy, please contact us at:
          </Text>
          <Text mt={2}>
            📧 Email:{" "}
            <Link href="mailto:support@thingwire.io" color="blue.500">
              support@thingwire.io
            </Link>
            <br />
            🌐 Website:{" "}
            <Link href="https://thingwire.io" color="blue.500" isExternal>
              https://thingwire.io
            </Link>
          </Text>
        </Box>
      </VStack>
    </Container>
  );
}

export default PrivacyPolicy; 